.wrapr {
  height: 200px;
  width: 400px;
  position: absolute;
  left: 0px;
  transition: background-color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}
.wrapr2 {
  height: 200px;
  width: 180px;
  position: absolute;
  left: 0px;
  transition: background-color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}
.wrapr:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.wrapr2:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.ico {
  margin-left: 180px;
  margin-top: 90px;
  opacity: 0;
  color: #ffff;
  padding: 10px;
  border-radius: 10px;
  background-color: #1b4552;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}
.ico2 {
  margin-left: 70px;
  margin-top: 90px;
  opacity: 0;
  color: #ffff;
  padding: 10px;
  border-radius: 10px;
  background-color: #1b4552;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}
.wrapr:hover .ico {
  opacity: 1;
}
.wrapr2:hover .ico2 {
  opacity: 1;
}
